// body {
// 	margin: 8px;
// }
@import 'src/assets/scss/variable';
@import 'src/assets/mixins/index';
.main-modify-branch-master {
  width: 1240px;
  margin: 0 auto;
  min-height: 800px;
  .body-wrapper {
    min-height: 650px;
  }
  .table-modify-branch {
    width: 100% !important;
  }

  .isRequired {
    border: 1px solid red !important;
  }

  .search {
    width: 100%;
    margin-top: 10px;
    font-size: 12px;

    .title-branch {
      text-align: left;

      p {
        margin-bottom: 0px;
      }
    }
  }

  .search table {
    border-collapse: collapse;
    border: 1px solid #fff;
  }

  .search th {
    background-color: #00a63c;
    color: #fff;
    width: 200px;
    border: 1px solid #fff;
    font-weight: normal;
    padding: 5px;
    text-align: center;
  }

  .search td {
    background-color: #cef6e3;
    width: 290px;
    border: 1px solid #fff;
    padding: 5px;

    input {
      margin: 1px;
      padding: 2px 4px;
    }

    select {
      border: 1px solid #75dc5a;
      margin: 1px;
      padding: 2px 4px;
    }

    .mid {
      width: 50%;
    }

    .min {
      width: 15%;
    }

    .middle {
      width: 30%;
    }

    .big {
      width: 90%;
    }

    label {
      margin-bottom: 0px;
    }

    .checkbox {
      margin: 3px 3px 3px 4px;
    }

    .radio {
      margin: 3px 3px 0px 5px;
    }
  }

  .check {
    border: 1px solid red !important;
  }

  .check-ok {
    border: 1px solid green !important;
  }

  .input-small {
    border: 1px solid #75dc5a;
    width: 60px;
  }

  .submitbutton {
    -webkit-appearance: push-button;
    user-select: none;
    white-space: pre;
    align-items: flex-start;
    text-align: center;
    cursor: default;
    color: buttontext;
    background-color: buttonface;
    box-sizing: border-box;
    padding: 1px 6px;
    border-width: 2px;
    border-style: outset;
    border-color: buttonface;
    border-image: initial;
  }
}


.create-branch-master-screen {
  &__table {
    width: 100%;

    tr {

      th, td {
        padding: 0 5px !important;
      }

      th {
        width: 200px !important;
        background-color: #7F7F7F !important;
      }

      th.header-title {
        text-align: left;
        font-size: large;
      }

      td {
        background-color: white !important;

        input, select {
          width: 100% !important;
          background-color: white;
          border: 1px solid #D2D2D2 !important;
          outline: none;
          height: 100%;
          height: 30px;

          &:disabled {
            background-color: #e6e0e0;
          }
        }

        select {
          cursor: pointer;
        }
      }

      td.inline {
        display: flex;
        width: 100%;
        border: 0;

        input {
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  .btn-create-branch-master {
    margin: 20px;
    width: 100px;
    background-color: #4F81BD;
    color: white;
    font-size: large;
    padding: 3px;
    font-weight: 500;
    outline: none;
  }
}
