.form-appoint-wrapper {
  margin-bottom: 20px;
  width: 100%;
  font-weight: bold;
  font-size: 1.2em;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  background: #ffffff;
  color: #0e5a8d;
  position: relative;
  padding: 15px;
  border: 2px solid #0e5a8d;

  .title-absolute {
    position: absolute;
    color: #0e5a8d;
    background: white;
    padding-top: 0px;
    height: 20px;
    margin-top: -33px;
    margin-left: 12px;
    padding-left: 10px;
    padding-right: 8px;
    font-size: 20px;
  }

  .form-content-appoint {
    display: flex;
    flex-direction: row;
    width: 100%;

    .content {
      width: 50%;
    }

    .form-table-calendar {
      display: flex;
      flex-direction: column;
      margin-right: 3px;

      // table {
      //   border-collapse: collapse;
      //   width: 100%;
      //   table-layout: fixed;
      //   word-wrap: break-word;
      // }

      .detail__form {
        border-collapse: unset;
        width: 100%;

        th {
          width: 14%;
          background: #0e5a8d;
          color: white;
          text-align: center;
          font-size: 11px;
        }

        td {
          background: white;
          padding: 0;
          font-size: 11px;
        }

        &--radio {
          margin: auto;
        }

        &--item {
          min-width: 0px;
          height: 27px;

          .input {
            text-align: center;
            border: 2px solid #0e5a8d;
            width: 100%;
            height: 100%;
            outline: none;
            color: #0e5a8d;
            font-weight: bold;
          }
        }

        &--group-item {
          display: grid;
          grid-template-columns: 25px 1fr;

          .item {
            border: 2px solid #0e5a8d;
            text-align: center;
            padding: 3px;
          }
        }



        input[type='checkbox'] {
          position: relative;
          cursor: pointer;
        }

        input[type='checkbox']:before {
          content: '';
          display: block;
          position: absolute;
          width: 15px;
          height: 15px;
          top: -2px;
          left: 0;
          background-color: #e9e9e9;
        }

        input[type='checkbox']:checked:before {
          content: '';
          display: block;
          position: absolute;
          width: 15px;
          height: 15px;
          top: -2px;
          left: 0;
          background-color: #1e80ef;
        }

        input[type='checkbox']:checked:after {
          content: '';
          display: block;
          width: 5px;
          height: 9px;
          border: solid white;
          border-width: 0 2px 2px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
          position: absolute;
          top: 0;
          left: 5px;
        }
      }
    }
  }

  .form-detail-date {
    width: 100%;
    border: 2px solid #0e5a8d;
    height: auto;
    min-height: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;

    .title-detail {
      width: 100%;
      color: #fff;
      background-color: #0e5a8d;
      padding: 2px 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .row-btn {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 5px;

      select {
        background-color: #fff;
        border: 1px solid #0e5a8d;
        min-width: 100px;
      }

      .form-tab {
        display: flex;
        border: 1px solid #0e5a8d;
        font-size: 11px;
        width: fit-content;
        margin-left: 30px;

        .tab {
          padding: 5px;
          min-width: 100px;
          text-align: center;
        }

        .active {
          color: #fff;
          background-color: #0e5a8d;
        }
      }
    }

    .table>tbody>tr:nth-child(2n+1)>td {
      background-color: #e8ecf4;
    }

    .form-table {
      width: 100%;
      overflow: auto;
      height: 300px;
      margin-bottom: 10px;
      border: 1px solid #0e5a8d;
      margin-top: 5px;
    }

    .table {
      width: 100%;
      font-size: 12px;
      border-collapse: separate;
      margin-bottom: 0px;
      position: relative;

      .group-working {
        position: absolute;
        margin-top: 2px;
        max-height: 25px;
        display: flex;
        flex-direction: row;

        p {
          overflow: hidden;
          width: 100%;
          margin-bottom: 0;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      .form-moving {
        background-color: #92d050;
        padding: 5px;
        text-align: center;
        display: flex;
        font-weight: normal;
        align-items: center;
        border-radius: 2px;
      }

      .form-order {

        padding: 5px;
        text-align: center;
        display: flex;
        font-weight: normal;
        background-color: #4f81bd;
        border-radius: 2px;
        align-items: center;
      }

      .row-table {
        background-color: #cfd7e7;
        position: relative;
        width: auto;

        .highlighted {
          background-color: #999;
        }

        // .time {
        //   width: calc(70% / 12) !important;

        // }

        .item-header {
          padding: 5px;
          align-items: center;
          border: none;
          font-size: 10px;
          // width: calc(30% / 4);
          text-align: center;
          width: 50px;

          p {
            overflow: hidden;
            width: 100%;
            margin-bottom: 0;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }

  }

  .row-btn-submit {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .detail__update {
    margin-top: 10px;
    width: 100%;
    min-height: auto;
    margin-bottom: 20px;

    .detail__table {
      border-collapse: unset;
      width: 100%;

      &--color {
        border: 2px solid #0e5a8d;
        background: #fff;
        color: #0e5a8d;
        padding: 5px;

        textarea {
          height: 100%;
          width: 100%;
        }

        .select-form {
          width: 100%;
          background-color: #fff;
          border: 1px solid #0e5a8d;
          height: 25px;
          font-size: 11px;
        }
      }

      &--color-text {
        color: black;
      }

      &--button {
        background-color: rgb(79, 129, 189);
        color: white;
        border-radius: 5px;
        border: 1px solid rgb(79, 129, 189);

        &:hover {
          background-color: #ffffff;
          text-decoration: none;
          color: #0e5a8d;
        }
      }

      &--th {
        padding: 15px 10px;
      }

      .th-child {
        width: 25%;
        height: 25px;
        padding: 0px;
      }

      .tr-child {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        input {
          width: 100px;
          border: 1px solid #0e5a8d;
        }

        select {
          width: 100px;
          border: 1px solid #0e5a8d;
        }

        .input-child {
          width: 200px;
          height: 25px;
        }
      }



      th {
        width: 10%;
        background: #0e5a8d;
        color: white;
        font-size: 11px;
        text-align: center;
      }

      &--form {
        padding: 0;
        font-size: 11px;
      }

      &--formdate {
        width: 33%;
        margin: 0 2px;
        font-size: 11px;
        padding: 10px 0;
      }

      &--text {
        font-size: 11px;
        margin: 0 2px;
        padding-top: 5px;
        // width: 280px;
        // margin-bottom: 0;
      }

      &--submit {
        height: 21px;
        margin-top: 2px;
        margin-left: 3px;
        margin-bottom: 2px;
        padding-top: 0;
      }
    }
  }
}
