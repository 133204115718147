.popup-update {
  opacity: 1;
  background-color: white;
  position: fixed;
  z-index: 300;
  width: 60vw;
  height: 410px;
  display: flex;
  flex-direction: column;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  border: solid 5px #387ca9;
  border-radius: 10px;
  padding: 10px;

  div.header {
    background-color: #387ca9;
    padding: 5px 0;
    height: 50px;
    margin-bottom: 5px;
    button {
      color: white;
      background-color: inherit;
      padding: 0 10px;
      border: none;
      height: 40px;
      &:active:focus {
        border: none;
        outline: 0;
      }
    }
  }

  .input-detail {
    height: 200px;
  }
  h2 {
    background-color: #387ca9;
    color: white;
  }

  input {
    border: 2px solid gray;
  }

  .label-input {
    background-color: gray;
    color: white;
    text-align: center;
    margin: 0;

    span {
      vertical-align: middle;
      display: inline-block;
    }
  }

  textarea.w-75 {
    padding: 40px 0;
    border: 2px solid gray;
  }
  .container-body {
    button {
      border: #0e5a8d 2px solid;
      padding: 5px 12px;
      border-radius: 4px;
      height: 100%;
      background-color: #387ca9;
      transition: 0.5s;
      color: #ffffff;
      cursor: pointer;
      &:nth-child(2) {
        margin-left: 10px;
      }
    }
  }
}

.hidden-item {
  visibility: hidden;
}

.right-button{
  float:right;
}
