@import 'src/assets/scss/variable';
@import 'src/assets/mixins/index';
#Result-branch-master {
  .text-banner {
    .small {
      font-size: calculateRem(20px);
    }
    .medium {
      font-size: calculateRem(24px);
    }
    .langer {
      font-size: calculateRem(30px);
    }
  }
  .small {
    font-size: calculateRem(10px);
  }
  .medium {
    font-size: calculateRem(12px);
  }
  .langer {
    font-size: calculateRem(15px);
  }
  .listTbl {
    width: 100%;
    background: #fff;
    margin-bottom: 40px !important;
  }
  .listTbl th {
    padding: 8px;
    min-width: 40px;
    background: #00a63c;
    border: 1px solid #fff;
    color: #fff;
    font-weight: normal;
    text-align: center;
    vertical-align: bottom;
  }
  .listTbl td {
    border: 1px solid #fff;
  }
  .listTbl tr {
    background-color: #efefef;
  }
  .listTbl tr:nth-child(odd) {
    background: #00a63c;
  }
  .user_btn {
    cursor: pointer;
    border-radius: 3px;
    display: inline-block;
    background: white;
    padding: 6px;
    width: 90%;
    margin: 3px 0px;
    color: #00a63c;
    border: 1px solid $white;
  }
  .no_tddiv {
    background: #33afd1;
    padding: 6px;
    border: thick #669999;
    color: #ffffff;
    text-align: center;
    font-size: 1.4em;
  }
  table td {
    background-color: #cef6e3;
    padding: 9px 10px;
    text-align: left;
  }
  .no-wrapper-client {
    .no-style {
      background: #00a63c;
      color: $white;
      padding: 4px 4px;
    }

    .small {
      font-size: calculateRem(14px);
    }

    .medium {
      font-size: calculateRem(16.8px);
    }

    .large {
      font-size: calculateRem(21px);
    }
  }

  .table-branch-list {
    border-collapse: separate !important;

    th {
      background-color: #BFBFBF;
      color: black;
    }

    th, td {
      border: 1px solid #D4D4D4;
    }

    td {
      background-color: white;

      .user_btn {
        background-color: #4F81BD;
        color: white;
        min-width: 93px;
      }

      .no-style {
        background-color: white;
        color: black;
      }
    }
  }
}
