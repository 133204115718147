.modal-dialog-custom {
  max-width: 900px;

  .modal-body {
    max-height: 900px;
    overflow: auto;
  }

  &--max-width {
    max-width: 90% !important;
  }

  &--resize {
    max-width: 1250px !important;
  }
}

.modal-dialog--w1000 {
  max-width: 1000px;

  .modal-body {
    max-height: 900px;
    overflow: auto;
  }

  &--max-width {
    max-width: 90% !important;
  }

  &--resize {
    max-width: 1250px !important;
  }
}

.button-modal {
  border: #0e5a8d 1px solid;
  padding: 0px 10px;
  border-radius: 2px;
  background-color: #387ca9;
  transition: 0.5s;
  color: #ffffff;
  cursor: pointer;
  min-width: 100px;
  font-size: 14px;
  height: 24px;

  &:hover {
    background-color: #ffffff;
    color: #387ca9;
  }

  &:disabled {
    background-color: #7f7f7f;
    cursor: not-allowed;
  }
}

.form-document-settings {
  width: 100%;
  max-height: 100%;
  min-height: 500px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: #0e5a8d 2px solid;
  border-radius: 4px;
  margin-bottom: 10px;

  .form-top {
    display: flex;
    flex-direction: column;
    border-bottom: #0e5a8d 2px solid;
    padding-bottom: 10px;

    .form-title-modal {
      border: #0e5a8d 2px solid;
      padding: 10px;
      border-radius: 4px;
      text-align: center;
      width: 100%;
      color: #0e5a8d;
      font-size: 18px;
      font-weight: bold;
    }

    .form-tree {
      border: #0e5a8d 2px solid;
      padding: 10px;
      border-radius: 4px;
      width: 100%;

      .form-tree-group {
        margin-top: 10px;

        .title-project {
          min-width: 100px;
          text-align: center;
        }

        ul {
          position: relative;
          list-style-type: none;
          margin-left: 40px;
          margin-top: 0px;
          padding-top: 5px;

          &:last-child {
            margin-bottom: 0px;
          }

          &::before {
            content: '';
            display: block;
            width: 0;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            border-left: 2px solid #0e5a8d;
          }

          .disable-item {
            cursor: not-allowed;
            background-color: #ffffff;
            color: #0e5a8d;
          }
        }

        li {
          position: relative;

          &::before {
            content: '';
            display: block;
            width: 31px;
            height: 2px;
            border-top: 2px solid #0e5a8d;
            margin-top: -7px;
            position: absolute;
            top: 1em;
            left: -39px;
            color: red;
          }

          &:last-child::before {
            background: #fff;
            height: auto;
            top: 1em;
            bottom: 0;
            left: -40px;
          }
        }

        div.d-flex {
          font-size: 1rem;
          font-weight: 600;
          position: relative;
          color: #0e5a8d;
          margin-bottom: 5px;
        }

        p {
          font-size: 1rem;
          font-weight: 600;
          position: relative;
          color: #0e5a8d;
          margin-bottom: 5px;
        }

        button {
          margin-left: 10px;
          border: #0e5a8d 1px solid;
          padding: 0px 10px;
          border-radius: 4px;
          background-color: #387ca9;
          transition: 0.5s;
          color: #ffffff;
          font-size: 14px;
          cursor: pointer;

          &:hover {
            background-color: #ffffff;
            text-decoration: none;
            color: #0e5a8d;
          }

          &:disabled {
            background-color: #7f7f7f;
            cursor: not-allowed;
          }
        }

        span {
          margin-left: 10px;
          border: #0e5a8d 2px solid;
          padding: 5px 12px;
          border-radius: 4px;
          background-color: #387ca9;
          transition: 0.5s;
          color: #ffffff;
          cursor: pointer;

          &:hover {
            background-color: #ffffff;
            text-decoration: none;
            color: #0e5a8d;
          }

          &:disabled {
            background-color: #7f7f7f;
            cursor: not-allowed;
          }
        }
      }

      .form-btn-tree {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        button {
          margin-right: 5px;
        }
      }
    }

    .form-btn-top {
      display: flex;
      justify-content: flex-start;
      margin: 5px 0;
    }

    .form-create {
      width: 100%;

      .row-left {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 5px;

        .form-item-create {
          margin-right: 2px;
        }
      }

      .row-right {
        display: flex;
        justify-content: flex-end;

        .form-item-create {
          margin-left: 2px;
        }
      }

      .row-create {
        width: 100%;

        .item-form-create {
          display: flex;
          flex-direction: row;
          height: 24px;
          font-size: 15px;

          select {
            min-width: 100px;
            text-align: center;
            height: 24px;
          }

          input {
            width: 100px;
            height: 24px;
          }
        }
      }

      .form-item-create {
        min-width: 100px;
      }
    }

  }

  .header-form-create {
    padding: 0 5px;
    text-align: center;
    border: 1px solid #7f7f7f;
    border-radius: 2px;
    background-color: #bfbfbf;
    font-size: 15px;
    margin-bottom: 2px;

    p {
      margin-bottom: 0px;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .form-update-document {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
    padding-bottom: 20px;
    min-height: 150px;

    .form-table {
      border-collapse: unset;
      display: flex;
      flex-direction: column;
      width: 100%;

      .right-margin {
        margin-right: 2px;
      }

      .right-margin:last-child {
        margin-right: 0px;
      }

      .form-btn-update {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }

      .row-table {
        display: flex;
        flex-direction: row;
        width: 100%;

        .item-content {
          display: flex;
          justify-content: center;
          align-items: center;

          .icon {
            border: 1px dashed #0e5a8d;
            width: 21px;
            height: 21px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            i {
              color: #0e5a8d
            }
          }

          .item-update {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            font-size: 15px;

            .part {
              width: 50%;
              height: 24px;
            }

            .basic-multi-select {
              height: 100%;

              .select__control {
                height: 100%;
              }
            }
          }
        }
      }
    }
  }

  .form-upload {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 5px 0 20px 50px;

    label {
      margin-bottom: 0px
    }

    input {
      height: 24px;
    }
  }
}
