.user-master-form-create-update-screen {
	.user-master-table {
    width: 100%;
    border-collapse: separate;

		tr {

			th, td {
        padding: 0 5px !important;
			}

			th {
			  width: 25% !important;
        text-align: center;
        background-color: #7F7F7F;
			}

			th.header-title {
			  text-align: left;
			  font-size: large;
			}

			td {
        border-left: 0;
        border-right: 0;
        background-color: #FFFFFF;

        &:last-child {
          padding-right: 0 !important;
        }

        label {
          display: flex;
          align-items: center;
          margin-bottom: 0;
          min-width: 50px;
          justify-content: center;
          border: 1px solid #CFCFCF;
        }

			  input, select {
          padding: 0 5px;
          width: 100% !important;
          background-color: white;
          border: 1px solid #CFCFCF !important;
          outline: none;
          height: 30px;

          &:disabled {
            background-color: #e6e0e0;
          }
        }

        input.input-err {
          border: 1px solid red !important;
        }

        .basic-multi-select {
          border: 1px solid #CFCFCF !important;

          input {
            border: none !important;
          }
        }

			  select {
				  cursor: pointer;
			  }

        .group-email-input {
          display: flex;

          .input {
            &__box {
              width: 30% !important;
            }

            &__email {
              &--w90 {
                width: 90% !important;
              }
            }
          }
          div {
            display: flex;
            align-items: center;
            padding-right: 10px;
            border: 1px solid #CFCFCF;

            .small-text {
              font-size: 12px;
              color: #7F7F7F;
              margin-right: 5px;
            }

            .email-label {
              width: 68px;
              display: flex;
              margin: 0 !important;
              align-items: center;
              border: 0;
            }

            .input-email-checkbox {
              height: 16px;
              cursor: pointer;

              &--isLock {
                width: 50% !important;
              }
            }
          }

          .btn-resend-confirm-code {
            height: 30px;
            min-width: 92px;
            justify-content: center;
            background-color: #4F81BD;
            color: white;
            display: flex;
            align-items: center;
            margin-left: 5px;
            font-weight: bold;
          }
        }

        .btn-generate-password {
          cursor: pointer;
          min-width: 100px;
          color: white;
        }

        .inline {
          display: flex;

          label {
            border-right: 0;
          }
        }

        .basic-multi-select {
          border: 0 !important;
        }

        .isRequired {
          border: 1px solid red !important;
          border-radius: 5px;
        }

        #group-arial-wraper {
          .group-arial {
            .group-arial__list {
              margin-bottom: 0;
            }
          }
        }
			}

			td.inline {
			  display: flex;
			  width: 100%;
			  border: 0;

			  input {
          &:last-child {
            margin-right: 0;
          }
			  }
      }

      td.group-birthday-input {
        .date-time-picker-wrapper, .bloodtype-input {
          flex: 1;
        }
      }
    }

    tr.group-name-input {
      td {
        &:last-child {
          padding-left: 0 !important;
        }
      }
    }
	}

  .group-action-user {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;

    .btn-submit-user {
      min-width: 160px;
      background-color: #4F81BD;
      color: #ffffff;
      font-size: large;
      padding: 3px;
      font-weight: 500;
      outline: none;
    }

    .inline-action {
      margin-bottom: 20px;

      .btn-submit-user:first-child {
        margin-right: 10px;
      }
    }
  }
}
