.form-body-project {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: #0e5a8d 2px solid;
  border-radius: 4px;
  margin-bottom: 10px;

  .form-get-code {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 10px;
    width: 100%;

    button {
      border: #0e5a8d 2px solid;
      padding: 5px 12px;
      border-radius: 4px;
      background-color: #387ca9;
      transition: 0.5s;
      color: #ffffff;
      cursor: pointer;
      margin-left: 10px;
      height: 34px;
      min-width: 100px;


      &:hover {
        background-color: #ffffff;
        color: #387ca9;
      }
    }
  }

  .group-input-project {
    border: #0e5a8d 2px solid;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    width: 60%;


    input,
    select {
      height: 30px;
      border: none;
      outline: none;
      // padding: 5px 12px;
      border-left: #0e5a8d 2px solid;
      padding: 0 5px;
      width: calc(100% - 200px);
    }

    .title {
      height: 30px;
      background-color: #0e5a8d;
      min-width: 200px;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .form-btn-setting {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    button {
      border: #0e5a8d 2px solid;
      padding: 5px 12px;
      border-radius: 4px;
      background-color: #387ca9;
      transition: 0.5s;
      color: #ffffff;
      cursor: pointer;
      min-width: 100px;

      &:hover {
        background-color: #ffffff;
        color: #387ca9;
      }

      &:disabled {
        cursor: not-allowed;
      }

    }
  }
}