@function calculateRem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}

@mixin breakpoint($point) {
  @media (min-width: $point) {
    @content;
  }
}
