.detail-basic-information-wrapper .detail-basic-table-wrapper .table-export-list-partner .table-bordered.perfomance-table {
  border-collapse: collapse;
}

.table-bordered.perfomance-table{
  border: 1px solid black;
}

.detail-basic-information-wrapper .detail-basic-table-wrapper .table-export-list-partner .table-bordered.perfomance-table tbody td:first-child {
  color:#0e5a8d;
}

.perfomance-table.table.table-bordered{
  *{
    background-color: white;
    border: 1px solid black;
    color:#0e5a8d;
  }
  tbody {
    tr > td{
      border: 1px solid black;
    }
  }
}

button.btn-update-perfomance {
  background-color: #387ca9;
  border-radius: 4px;
  border: #0e5a8d 2px solid;
  color: #ffffff;
  cursor: pointer;
  width: 25%;
  height: 100%;
  margin-bottom: 15px;
  padding: 5px 12px;
  transition: 0.5s;
  overflow: hidden;
  text-overflow: ellipsis;
}

.detail-basic-information-wrapper .detail-basic-table-wrapper .table-export-list-partner .table-bordered.perfomance-table tbody td button{
  overflow: hidden;
  text-overflow: ellipsis;
}

.detail-basic-information-wrapper .detail-basic-table-wrapper .table-export-list-partner .table-bordered tbody td button.btn-services {
  width: auto;
}