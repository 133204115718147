@import 'src/assets/scss/variable';
@import 'src/assets/mixins/index';

.table-list-anken-wrapper {
  .group-button {
    .small {
      font-size: calculateRem(10px) !important;
    }

    .medium {
      font-size: calculateRem(12px) !important;
    }

    .large {
      font-size: calculateRem(15px) !important;
    }
  }
  thead {
    tr:nth-child(odd) {
      background-color: $dark-brown;
      color: white;
      font-size: 11px;
    }
  }

  tbody {
    tr:nth-child(odd) {
      background-color: $light-brown;
    }

    tr:nth-child(even) {
      background-color: $brown;
    }

    font-size: 12px;
  }

  td {
    border: 1px solid $white;
    //text-align: center;
    vertical-align: middle;
  }

  th {
    border: 1px solid $white;
    //text-align: center;
    vertical-align: middle;
  }

  .small {
    td,
    th {
      font-size: calculateRem(10px);
    }
  }

  .medium {
    td,
    th {
      font-size: calculateRem(12px);
    }
  }

  .large {
    td,
    th {
      font-size: calculateRem(15px);
    }
  }

  .no-wrapper {
    .no-style {
      background: $dark-blue;
      color: $white;
      padding: 0px 12px;
    }

    .small {
      font-size: calculateRem(14px);
    }

    .medium {
      font-size: calculateRem(16.8px);
    }

    .large {
      font-size: calculateRem(21px);
    }
  }

  .button-user {
    background: $white;
    border: 1px solid $dark-blue;
    border-radius: 3px;
    cursor: pointer;
  }
  .button-user-end {
    background: gray;
    border: 1px solid $dark-blue;
    cursor: pointer;
    border-radius: 3px;
  }
  //table {
  //  border-collapse: collapse;
  //  border-spacing: 0;
  //  width: 100%;
  //  //border: 1px solid #ddd;
  //}
}
