@font-face {
  font-family: 'fontello';
  src: url('./font/fontello.eot');
  src: url('./font/fontello.eot?84226854#iefix') format('embedded-opentype'),
    url('./font/fontello.woff2?84226854') format('woff2'),
    url('./font/fontello.woff?84226854') format('woff'),
    url('./font/fontello.ttf?84226854') format('truetype'),
    url('./font/fontello.svg?84226854#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?84226854#fontello') format('svg');
  }
}
*/

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-star:before {
  content: '\e800';
}

/* '' */
.icon-star-empty:before {
  content: '\e801';
}

/* '' */
.icon-star-half:before {
  content: '\e802';
}

/* '' */
.icon-user:before {
  content: '\e803';
}

/* '' */
.icon-chart-bar:before {
  content: '\e804';
}

/* '' */
.icon-upload:before {
  content: '\e805';
}

/* '' */
.icon-search:before {
  content: '\e806';
}

/* '' */
.icon-arrows-cw:before {
  content: '\e807';
}

/* '' */
.icon-export:before {
  content: '\e808';
}

/* '' */
.icon-help-circled:before {
  content: '\e809';
}

/* '' */
.icon-up-open:before {
  content: '\e80a';
}

/* '' */
.icon-down-open:before {
  content: '\e80b';
}

/* '' */
.icon-users:before {
  content: '\e80c';
}

/* '' */
.icon-pencil:before {
  content: '\e80d';
}

/* '' */
.icon-list-alt:before {
  content: '\e80e';
}

/* '' */
.icon-link-ext:before {
  content: '\f08e';
}

/* '' */
.icon-phone-squared:before {
  content: '\f098';
}

/* '' */
.icon-gauge:before {
  content: '\f0e4';
}

/* '' */
.icon-upload-cloud:before {
  content: '\f0ee';
}

/* '' */
.icon-star-half-alt:before {
  content: '\f123';
}

/* '' */
.icon-database:before {
  content: '\f1c0';
}

/* '' */
.icon-cogs:before {
  content: '\f085';
}

/* '' */
.icon-file-excel:before {
  content: '\f1c3';
}

/* '' */
.icon-copyright:before {
  content: '\f1f9';
}

/* '' */
.icon-user-secret:before {
  content: '\f21b';
}

/* '' */
.icon-product-hunt:before {
  content: '\f288';
}

/* '' */
.icon-question-circle:before {
  content: '\f059';
}

/* '' */