// ======= # GENERAL COLOR # ======= //
$blue: #33afd1;
$dark-blue: #222249;
$green: #619923;
$orange: #f88a45;
$white: #fff;
$color-body: #efeee9;
$dark-brown: #585858;
$brown: #c6c6c6;
$light-brown: #efefef;
$hex: #ccc;
$light-green: #328562;
$gray: #808080;
$pink: #e97e7e;
$black: #000;
$blue-text : #0e5a8d;

$all-font-color: (
  blue: $blue,
  dark-blue: $dark-blue,
  green: $green,
  orange: $orange,
);

$all-background: (
  blue: $blue,
  dark-blue: $dark-blue,
  green: $green,
  orange: $orange,
);

$all-font-size: 7 8 10 12 14 16 18 20 24 30 40 50;
$all-width-percent: 10 15 20 30 40 50 60 70 80 90;

// ======= # DEVICE BREAKPOINT # ======= //
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1366px;

// ======= # Z-INDEX # ======= //
$index-header: 1000;
$index-sidebar: 988;
$index-scroll-top: 1001;
$index-picker-emoji: 999;
$index-error-page: 999;
$index-icon: 10;
$index-content: 10;
$index-bg: -1;

$bd-radius: 10px;
