@import 'src/assets/scss/variable';
@import 'src/assets/mixins/index';

.table-confirm {
  &--wrapper {
    thead {
      tr:nth-child(odd) {
        background-color: $white;
        color: $blue-text;
        font-size: 11px;
      }
    }

    tbody {
      tr:nth-child(odd) {
        background-color: $white;
      }

      tr:nth-child(even) {
        background-color: $white;
      }

      font-size: 12px;
    }

    td {
      border: 1px solid $black;
      text-align: center;
      font-weight: 100;
      color: $blue-text;
      padding: 0.75rem;
    }

    th {
      border: 1px solid $black;
      text-align: center;
      font-weight: bold;
      color: $blue-text;
      padding: 10px;
      font-size: 12px;
    }
  }
  &--btn_edit {
    background: $blue-text;
    padding: 3px 10px;
    border-radius: 3px;
    text-decoration: none;
    color: $white;
    &:hover {
      color: $white;
      text-decoration: none;
      background: #007bff;
    }
  }
}
