$all-font-size: 7 8 10 11 12 14 16 18 20 24 30 40 50;
$all-width-size:2 4 5 6 7 8 10 15 20 30 35 40 45 55 60 65 70 80 85 90 95;

@mixin fontSize($size) {
  font-size: $size!important;
  font-size: calculateRem($size);
}

@each $size in $all-font-size {
  .fz-#{$size} {
    @include fontSize($size * 1px);
  }
}

@mixin fontWidth($size) {
  width: $size !important;
}

@each $size in $all-width-size {
  .w-#{$size} {
    @include fontWidth($size * 1%);
  }
}