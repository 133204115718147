

.list {
  width: 100%;
  margin-top: 10px;
  overflow-x: hidden;
  font-family: "ＭＳ Ｐゴシック", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, Osaka, "MS PGothic", sans-serif;
  font-size: 11px;
}
.list table,
.list_mini table {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
  word-wrap: break-word;
}
.list th,
.list_mini th {
  background-color: #1c2a53;
  color: #fff;
  border: 1px solid #fff;
  font-weight: normal;
  text-align: center;
}
.list td,
.list_mini td {
  background-color: #bcd0eb;
  padding: 5px;
  border: 1px solid #fff;
}
.list td.result_righttd {
  text-align: right;
}

.list a {
  color: #28982a;
  text-decoration: none;
}
.list a:hover {
  color: #ec6923;
  text-decoration: none;
}

.template_memo_table {
  border-collapse: collapse;
}
.template_memo_table th,
.template_memo_table td {
  vertical-align: top;
  border: 1px solid #ddd;
}
.template_memo_table th {
  background-color: #7d6356;
  color: #fff;
}
.template_memo_editrow td {
  background-color: #ebe6ad;
}
.template_memo_table label {
  display: inline-block;
}
