.edit-branch-master-screen {
   &__table {
      width: 100%;

      tr {

         th, td {
            padding: 0 5px !important;
         }

         th {
            width: 200px !important;
            background-color: #7F7F7F !important;
         }

         th.header-title {
            text-align: left;
            font-size: large;
         }

         td {
            background-color: white !important;

            input, select {
               width: 100% !important;
               background-color: white;
               border: 1px solid #D2D2D2 !important;
               outline: none;
               height: 100%;
               height: 30px;

               &:disabled {
               background-color: #e6e0e0;
               }
            }

            select {
               cursor: pointer;
            }

         }

         td.inline {
            display: flex;
            width: 100%;
            border: 0;

            input {
               &:last-child {
               margin-right: 0;
               }
            }
         }
      }
   }

   .btn-edit-branch-master {
      margin: 20px;
      width: 100px;
      background-color: #4F81BD;
      color: white;
      font-size: large;
      padding: 3px;
      font-weight: 500;
      outline: none;
   }
 }
