.box-shadow-none {
  box-shadow: none;
}

.cursor-pointer {
  cursor: pointer;
}

.isRequired {
  border: 1px solid red !important;
}

.btn-pdf-125 {
  width: 125px;
}

.radio-button {
  display: inline-block;
  margin-bottom: .5rem;
  margin-left: 10px;

  &--mg25 {
    margin-left: 25px;
  }

  &--mg50 {
    margin-left: 50px;
  }
}

.chat {
  &--num {
    position: absolute;
    font-size: 0.7em;
    top: -5px;
    color: #fff;
    right: -1px;
  }
}

.label {
  &__custom {
    width: 50%;
    margin: auto;
    text-align: center;
    min-width: 150px;
    height: 100%;
    border: #0e5a8d 2px solid;
    padding: 5px 12px;
    border-radius: 4px;
    background-color: #387ca9;
    transition: 0.5s;
    color: #ffffff;
    height: 32px;
    min-width: 120px;
    outline: none;
    display: inline-block;

    &--w45 {
      width: 45%;
      margin: unset;
    }

    &--pd10 {
      padding-bottom: 10px;
    }

    &--resend {
      margin: 0;
      background: #222249 !important;
      color: #fff !important;
    }
  }

  &__background {
    background: #ccc8c8;
    border: 1px solid;
    margin-right: 10px;
    padding-left: 5px;
    padding-right: 5px;
  }

  &__schedule {
    background-color: #002060;
    border: 1px solid #002060;
    margin-left: 10px;
    margin-right: 10px;
    padding: 4px;
    color: #ffffff;
    margin-bottom: 4px;
    padding-right: 10px;
    padding-left: 10px;

    &--col-resize {
      padding-right: unset;
      justify-content: space-between;
    }

    &--border {
      border: 1px solid black;
    }
  }

  &__visitDate {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
    word-wrap: break-word;
  }
}

.collap {
  &--style {
    border: 2px solid #0e5a8d;
    padding: 10px;
  }

  &__child {
    padding: 0px 10px;

    &--border {
      border: 2px solid #0e5a8d;
    }
  }
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.select {
  &__custom {
    &--w60 {
      margin-top: 10px;
      min-width: 45%;
      max-width: 60%;
    }
  }

  &--result {
    width: 100%;
    background-color: #ffffff;
  }

  &--w100 {
    width: 100px;
  }

  &--h60 {
    height: 60% !important;
  }

  &--h100 {
    height: 100% !important;
  }

  &--hauto {
    height: auto !important;
  }

  &--w80 {
    width: 80%;
  }

  &--w50 {
    width: 50%;
  }

  &--w100 {
    width: 100% !important;
  }
}

.table {
  &--w60 {
    width: 60%;
  }

  &--pdb10 {
    padding-bottom: 10px;
  }

  &--w100 {
    width: 100%;
  }

  &--w10 {
    width: 10%;
  }

  &--w20 {
    width: 20%;
  }

  &--w40 {
    width: 40%;
  }

  &--w50 {
    width: 50%;
  }

  &--w15 {
    width: 15%;
  }

  &--w85 {
    width: 85%;
  }
  &--w95 {
    width: 95%;
  }

  &--w3 {
    width: 3%;
  }

  &--w15 {
    width: 15%;
  }

  &--w30 {
    width: 30%;
  }

  &__custom {
    tbody {
      tr {
        th {
          width: 25%;
        }

        td {
          width: 75%;
        }
      }
    }
  }

  &__condition {
    height: 200px;
    overflow: auto;
    width: 100%;
  }

  &--nowrap {
    white-space: nowrap;
  }

  &__user {
    width: 100%;
    margin-top: 10px;

    table {
      border-collapse: collapse;
      border: 1px solid #FFF;
    }

    th {
      width: 200px;
      background-color: #7f7f7f;
      color: #ffffff;
      border: 1px solid #FFF;
      font-weight: normal;
      padding: 5px;
    }

    td {
      background-color: #ffffff;
      width: 290px;
      border: 1px solid #FFF;
      padding: 5px;

      .select__control {
        border: 1px solid #767676 !important;
      }

      .select__input {

        input {
          border: 0px !important;
        }
      }

      input {
        border: 1px solid #767676 !important;
      }

      .input-w50 {
        width: 49% !important;
      }

      .input-err {
        border: 1px solid red !important;
      }

      select {
        border: 1px solid #767676 !important;
      }
    }

    &--btn-pw {
      padding: 0px;
      margin-left: 15px;
      background-color: gray;
      border: 1px solid gray;
    }
  }
}

.input {
  &--resize {
    margin: 0px 5px;
  }

  &__custom {
    &--w75 {
      width: 75%;
    }

    &--w90 {
      width: 90% !important;
    }

    &--w25 {
      width: 25%;
      background: #ccc8c8;
      color: #0e5a8d;
      border: 1px solid #1C2A53;
      font-weight: normal;
      text-align: left;
    }
  }
}

.form {
  &--mgb15 {
    margin-bottom: 15px;
  }

  &--mgr15 {
    margin-right: 15px;
  }

  &--pd {
    padding: 3px;
  }

  &--border-bl {
    border: 1px solid black;
  }
}

.checkbox {
  &__custom {
    &--unset {
      width: unset !important;
      border: unset !important;
      border-radius: unset !important;
      height: unset !important;
    }

    &--partner {
      margin-left: 10px !important;
    }
  }
}

.grid {
  &--end {
    justify-content: flex-end !important;
  }

  &__flex {
    display: flex;

    &--center {
      justify-content: center;
    }

    &--space-between {
      justify-content: space-between;
    }
  }

  &--pd15 {
    padding-right: 15px;
    padding-left: 15px;
  }

  &--pdr20 {
    margin-right: 40px;
  }

  &--center {
    align-items: center;
  }

  &--mgunset {
    margin: unset !important;
  }
}

.wrapper {
  &--unset-height {
    min-height: unset;
  }
}

.detail {
  &__table {
    &--textarea {
      &:disabled {
        background-color: #ccc8c8;
      }
    }

    &--layout {
      table-layout: unset !important;
    }
  }
}

@keyframes status-pulse-red {
  0% {
    background-color: #ff0000;
  }

  100% {
    background-color: #DDD;
  }
}

@keyframes status-pulse-green {
  0% {
    background-color: #92d050;
  }

  100% {
    background-color: #DDD;
  }
}

@keyframes status-pulse-blue {
  0% {
    background-color: #00b0f0;
  }

  100% {
    background-color: #DDD;
  }
}

@keyframes status-pulse-blue-d {
  0% {
    background-color: #0070c0;
  }

  100% {
    background-color: #DDD;
  }
}

@keyframes status-pulse-gray {
  0% {
    background-color: #7f7f7f;
  }

  100% {
    background-color: #DDD;
  }
}

@keyframes status-pulse-yellow {
  0% {
    background-color: #ffff00;
  }

  100% {
    background-color: #DDD;
  }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation : landscape) {
  body {
    zoom: 0.825;

    table {
      border-spacing: 2px !important
    }

    .page_name {
      &--nowrap {
        white-space: unset !important;
      }
    }
  }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation : portrait) {
  body {
    zoom: 0.64;

    table {
      border-spacing: 2px !important
    }

    .page_name {
      &--nowrap {
        white-space: unset !important;
      }
    }

    .header-wrapper-master-setting {
      .menu {
        height: 188px !important;
      }
    }
  }
}
