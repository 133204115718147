@import 'src/assets/scss/variable';
@import 'src/assets/mixins/index';

.wrapper-search-branch-master {
  .title-name {
    line-height: 1.3em;
    margin-bottom: 16px;
    font-weight: bolder;
  }

  .clearfix {
    overflow: hidden;
    _overflow: visible;
  }

  .small {
    font-size: calculateRem(18px);
  }

  .medium {
    font-size: calculateRem(21.6px);
  }

  .langer {
    font-size: calculateRem(27px);
  }

  .button {
    .btn-search {
      background: #00a63c;
      cursor: pointer;
      color: white;
      padding: 10px 30px;
      box-shadow: none;
    }
  }
  .table-list {
    border: 1px solid #ccc;

    .small {
      font-size: calculateRem(10px);
    }

    .medium {
      font-size: calculateRem(12px);
    }

    .langer {
      font-size: calculateRem(15px);
    }
  }

  .table-text {
    width: 170px;
    padding: 10px 9px;
  }

  .button-client {
    font-size: 1.25em;
  }

  .tab_condit_input_item {
    background-color: #00a63c;
    color: #fff;
    border-radius: 2px;
  }

  .tab_condit {
    padding: 0 !important;

    .tab_condit_input {
      height: 100%;
    }

    input[type='text'] {
      width: 70%;
      border: 1px solid #ccc;
      border-radius: 2px;
    }
  }

  .form-search {
    &__label {
      display: flex;
      align-items: center;
      padding: 0 20px;
      color: white;
      background-color: #4F81BD;
      margin-right: 10px;
      border-radius: 5px;
    }

    &__input {
      margin-right: 10px;
    }

    &__submit {
      display: flex;
      align-items: center;
      padding: 0 20px;
      color: white;
      background-color: #4F81BD;
      margin-right: 10px;
      border-radius: 5px;

      i {
        margin-right: 5px;
      }
    }
  }
}

