@import 'src/assets/scss/variable';
@import 'src/assets/mixins/index';

.table-staff-branch-wrapper {
  thead {
    tr:nth-child(odd) {
      background-color:  #00a63c ;
      color: white;
      font-size: 11px;
    }
  }
  tbody {
    tr:nth-child(odd) {
      background-color: #cef6e3;
    }
    tr:nth-child(even) {
      background-color: #cef6e3;
    }
    font-size: 12px;
  }

  td {
    border: 1px solid $white;
    text-align: center;
  }
  th {
    border: 1px solid $white;
    text-align: center;
  }
  .small {
    td,
    th {
      font-size: calculateRem(10px);
    }
  }
  .medium {
    td,
    th {
      font-size: calculateRem(12px);
    }
  }
  .large {
    td,
    th {
      font-size: calculateRem(15px);
    }
  }
  .no-wrapper {
    .no-style {
      background: $dark-blue;
      color: $white;
      padding: 0px 12px;
    }
    .drak-blue {
      background: $dark-blue;
    }
    .blue {
      background: $blue;
    }
    .green {
      background: #00a63c ;
    }
    .small {
      font-size: calculateRem(14px);
    }
    .medium {
      font-size: calculateRem(16.8px);
    }
    .large {
      font-size: calculateRem(21px);
    }
  }
  .wrapper-sort {
    .drak-blue {
      color: $dark-blue;
    }
    .blue {
      color: $blue;
    }
    .green {
      color: #00a63c ;
    }
  }
  .btn-edit {
    color: black;
    text-decoration: none;
  }
}
