.setting-master-menu-wrapper {
  .wrapper-btn-colappse {
    .btn {
      width: 100%;
      background: #e7e7e7;
      border: #0e5a8d 2px solid;
      font-weight: bold;
      cursor: pointer;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      margin-bottom: 10px;
      color: #0e5a8d;
    }
  }

  .react-datepicker-wrapper {
    width: 20% !important;
  }

  .table-search {
    th {
      text-align: center;
      //background-color: #1c2a53;
      color: #fff;
      width: 200px;
      border: 1px solid #fff;
      font-weight: normal;
      padding: 5px;
      font-size: 11px;
    }

    td {
      background-color: #bcd0eb;
      width: 290px;
      border: 1px solid #fff;
      padding: 5px;

      input {
        font-size: 11px;
      }

      font-size: 13px;
    }
  }

  .btn-hideshow {
    width: 134px;
    //height: 18px;
    background-color: white;
    border: 1px solid #84b7dc;
    text-align: center;
    padding: 3px;
    cursor: pointer;
  }

  .hideshow2 {
    font-size: 12px;
  }

  .SearchButton {
    // margin-top: 10px;
    padding: 5px;
  }

  .btnS {
    min-width: 200px;
    font-size: 16px;
    text-align: center;
    padding: 2px;
  }

  .btnSubmit {
    background: #8e8e8e;
    color: #ffffff;
    display: inline !important;
    border: 2px solid #7c7c7c !important;
  }

  .btnReset {
    background: #f7f6f6;
    color: #707070;
    display: inline !important;
    border: 2px solid #aaadaa !important;
  }

  .searchbar {
    width: 100%;
    text-align: center;
    // border: #0e5a8d 2px solid;
    font-weight: bold;
    cursor: pointer;
    font-size: 1.2em;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    // background: #0e5a8d !important;
    color: black;
  }

  .icon-searchbar {
    font-size: 24px;
  }
}

.content-view {
  margin-bottom: 10px;
  border: #0e5a8d 2px solid;
  background-color: #ffffff;
  border-radius: 4px;
  padding: 30px 0 !important;

  &--label-unset {
    width: unset !important;
  }

  .text-root {
    font-size: 1rem;
    font-weight: 600;
  }
  .form-tree--left {
    position: relative;
      list-style-type: none;
      margin-left: 50px;
      margin-top: 10px;
      padding-top: 10px;
      &::before {
        content: '';
        display: block;
        width: 0;
        position: absolute;
        top: -3px;
        bottom: 0;
        left: -10px;
        border-left: 2px solid #0e5a8d;
      }
  }

  .form-tree--top {
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 31px;
      height: 2px;
      border-top: 2px solid #0e5a8d;
      margin-top: -7px;
      position: absolute;
      top: -6px;
      left: -10px;
    }

  }

  .form-tree--bottom {
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 31px;
      height: 2px;
      border-bottom: 2px solid #0e5a8d;
      margin-top: -7px;
      position: absolute;
      bottom: 0;
      left: -10px;
    }

  }

  .form-tree--show {
    position: relative;
    }

    .form-tree--on {
      width: 30px;
      height: 30px;
      border : 2px solid #0e5a8d ;
      position: absolute;
      text-align: center;
      left: -24px;
      background : white;
      top: 0;
      bottom: 50px;
      margin: auto;

      }

  .form-tree {
    .title-project {
      min-width: 100px;
      text-align: center;
      margin-left: 10px;

      &--left {
        text-align: left;
      }
    }

    ul {
      position: relative;
      list-style-type: none;
      margin-left: 50px;
      margin-top: 10px;
      padding-top: 10px;

      &:last-child {
        margin-bottom: 30px;
      }

      &::before {
        content: '';
        display: block;
        width: 0;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        border-left: 2px solid #0e5a8d;
      }

      .disable-item {
        cursor: not-allowed;
        background-color: #ffffff;
        color: #0e5a8d;
      }
    }

    li {
      position: relative;

      &::before {
        content: '';
        display: block;
        width: 31px;
        height: 2px;
        border-top: 2px solid #0e5a8d;
        margin-top: -7px;
        position: absolute;
        top: 1em;
        left: -39px;
        color: red;
      }

      &:last-child::before {
        background: #fff;
        height: auto;
        top: 1em;
        bottom: 0;
        left: -40px;
      }
    }

    p {
      font-size: 1rem;
      font-weight: 600;
      position: relative;
      color: #0e5a8d;
      margin-bottom: 10px;
    }

    button {
      margin-left: 10px;
      border: #0e5a8d 2px solid;
      padding: 2px 12px;
      border-radius: 4px;
      background-color: #387ca9;
      transition: 0.5s;
      color: #ffffff;
      cursor: pointer;

      &:hover {
        background-color: #ffffff;
        text-decoration: none;
        color: #0e5a8d;
      }
    }

    span {
      margin-left: 10px;
      border: #0e5a8d 2px solid;
      padding: 5px 12px;
      border-radius: 4px;
      background-color: #387ca9;
      transition: 0.5s;
      color: #ffffff;
      cursor: pointer;

      &:hover {
        background-color: #ffffff;
        text-decoration: none;
        color: #0e5a8d;
      }
    }
  }


}

.form-btn-modal {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-direction: row;
}

.form-search {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: row;
  margin-top: 10px;

  .group-input {
    border: #0e5a8d 2px solid;
    border-radius: 4px;
    display: flex;
    flex-direction: row;

    input {
      height: 30px;
      border: none;
      outline: none;
      // padding: 5px 12px;
      border-left: #0e5a8d 2px solid;
      text-indent: 5px;
    }

    .title {
      height: 30px;
      background-color: #0e5a8d;
      min-width: 120px;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.btn-tree {
  margin-left: 10px;
  border: #0e5a8d 2px solid;
  // padding: 5px 12px;
  border-radius: 4px;
  background-color: #387ca9;
  transition: 0.5s;
  color: #ffffff;
  cursor: pointer;
  height: 32px;
  min-width: 120px;
  outline: none;

  &:hover {
    background-color: #ffffff;
    text-decoration: none;
    color: #0e5a8d;
  }
}
