//@import "src/assets/scss/_variable.scss";
@import 'src/assets/mixins/index';
.wrapper-button-branch-master-all {

  a {
    background-color: #4F81BD;
    text-decoration: none;
    color: white;
    padding: 2px 20px;
    font-weight: bold;
  }

  .wrapper-button-branch-master {
    padding: 10px 30px;
    color: white;
    cursor: pointer;

    .title {
      font-family: "ＭＳ Ｐゴシック", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, Osaka, "MS PGothic", sans-serif;
      line-height: 1.2em;
    }

    .icon-master {
      color: white;
      font-size: 20px;
    }

    .small {
      font-size: calculateRem(18px);
    }
    .medium {
      font-size: calculateRem(21.6px);
    }
    .langer {
      font-size: calculateRem(27px);
    }
  }
  .blue {
    background: #33afd1;
  }
  .green {
    background: green;
  }
  .dark-blue {
    background: yellow;
  }
  .wrapper-button-client-master:hover {
    opacity: 0.7;
    .small {
      font-size: calculateRem(12.5px);
    }
    .medium {
      font-size: calculateRem(15px);
    }
    .langer {
      font-size: calculateRem(18.75px);
    }
  }
}
